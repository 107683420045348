import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Empty, notification, Table } from 'antd'
import { Error, Loading } from '../../ui'
import dayjs from 'dayjs'

const pageSize = 100

const status_generator = (status) => {
	let title = ''
	
	if (status === 'created') title = 'Создан'
	if (status === 'duplicated') title = 'Дубликат'
	if (status === 'confirmed') title = 'Подтвержден'
	if (status === 'used') title = 'Использован'
	if (status === 'skipped') title = 'Пропущен'
	
	return title
}

export const Calls = () => {
	const [state, setState] = useState([])
	
	const { data, loading, error, refetch } = useQuery(GETTER, { fetchPolicy: 'network-only' })
	const [ConfirmCall] = useMutation(CONFIRM_CALL)
	
	const confirmCall = id => {
		ConfirmCall({
			variables: { id },
			refetchQueries: [{ query: GETTER }]
		}).then(({ data: { confirmCall } }) => {
			if (confirmCall.status === 'success') notification.success({ message: 'Успешно', description: 'Вы подтвердили звонок' })
			else notification.error({ message: 'Ошибка', description: confirmCall.message })
		}).catch(e => {
			console.log(e)
			notification.error({ message: 'Ошибка', description: e.message })
		})
	}
	
	const columns = [
		{
			title: 'Дата звонка', dataIndex: 'createdAt', key: 'createdAt', width: 100,
			render: title => dayjs(title).format('DD.MM.YYYY HH:mm:ss')
		},
		{
			title: 'Телефон клиента', dataIndex: 'phone', key: 'phone', width: 100,
			render: title => title
		},
		{
			title: 'Провайдер', dataIndex: 'provider', key: 'provider', width: 100,
			render: title => title
		},
		{
			title: 'Статус', dataIndex: 'status', key: 'status', width: 100, align: 'center',
			render: title => status_generator(title)
		},
		{
			title: 'Действия', dataIndex: 'actions', key: 'actions', width: 150, align: 'right',
			render: (_, record) => {
				return <Button
					style={{ minWidth: 150 }}
					disabled={record.status !== 'created'}
					onClick={() => confirmCall(record.id)}
					type='primary'
				>
					{record.status === 'created' ? 'Подтвердить' : 'Выполнено'}
				</Button>
			}
		}
	]
	
	useEffect(() => {
		if (data) {
			setState([...data.getCalls])
		}
	}, [data])
	
	if (error) return <Error error={error} refetch={refetch}/>
	if (loading && !state.length) return <Loading/>
	
	return <div style={{ padding: '0 24px 0' }}>
		
		{!!state.length ? <>
			<Table
				rowKey='id'
				dataSource={state}
				columns={columns}
				style={{ marginTop: 16 }}
				pagination={{
					position: ['topRight', 'bottomRight'],
					pageSize
				}}
			/>
		</> : <Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={'У Вас нет клиентов'}
		/>}
	</div>
}

const GETTER = gql`
	query {
		getCalls {
			id
			phone
			provider
			status
			createdAt
		}
	}
`

const CONFIRM_CALL = gql`
	mutation($id: ID!) {
		confirmCall(id: $id) {
			id
			status
			message
		}
	}
`
